<template>
  <div ref="target">
    {{ output.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
  </div>
</template>

<script setup lang="ts">
import { TransitionPresets } from "@vueuse/core";

const { value, duration } = defineProps<{
  value: number;
  duration?: number;
}>();

const target = ref(null);
const targetIsVisible = useElementVisibility(target);

const source = ref(0);

const output = useTransition(source, {
  duration: duration ?? 2000,
  transition: TransitionPresets.easeInOutCubic,
});

watch(targetIsVisible, () => {
  source.value = value;
});
</script>
